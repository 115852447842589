export const lit = {
    'EN': {
        title1: 'Techno Avia',
        subtitle1: 'Large Scale FGF 3D printing, manufacturing of equipment from polymer materials for composite products',
        contactUs: 'Request a call',
        
        main: 'Home',
        about: 'Company',
        equipment: 'Solutions ',
        services: 'Servicies',
        contacts: 'Contact',
        
        title2: 'Company',
        title3: 'Facility equipment',
        title4: 'Large scale robotic FGF 3D printer',
        title5: 'Robotic system for rough milling',
        title6: 'Multi axis milling machine',
        title7: 'Our servicies',
        
        desc1: 'Techno Avia is a full-cycle production company with advanced technical equipment, highly qualified staff and a quality management system according to aerospace standards. The enterprise is engaged in the development and production of the tools, jigs and molds through 3D printing. We develop complete large-scale 3D printing facilities for the End-users. The core feature of our product is a custom software, which is optimized for the whole production process, and provides products stability.',
        desc2: 'Heavy industrial robot-based printer with extruder productivity  of over 15kg/hour for large-scale 3D printing. This model will be an excellent solution for an industrial enterprise. The industrial 3D printer operates  using FJF technology - layer-by-layer construction of composite polymers. The construction area of 6000x3000x2000 mm ensures printing of forms of large-sized parts. Allows you to produce a mold within 24 hours without additional costs. The design of the printer and the adopted design solutions ensure reliability, accuracy, speed of printing, and open up the possibility of producing forms of any degree of complexity (curved configuration, internal cavities, a large number of unique sections in various planes).',
        desc3: 'The large-scale FGF printing technology implies the presence of a large allowance when creating a workpiece, and increasing in the overall productivity of the complex is achieved by separating rough and finishing machining into different equipment. For rough machining , an industrial robot with a high-performance spindle and a working area equal to an FGF printer is used. It performs initial processing and preparation of the product for finish machining.',
        desc4: 'Ordinary large multi axis milling machines with a processing area of 6000mm x 1800mm x 1200mm imposes limitations on the maximum size of the workpiece, and requires additional engineering and technological preparation for production. However, it adds the capability to achieve geometric accuracy within the highest quality standards and surface roughness down to Ra 0.8, ensuring high-quality of the finished product.',
        desc5: <div>● Tooling production   according to the customer's technical specifications in the required quantities.<br/><br/>● Full installation of a technological complex similar to ours at the customer’s facility, which includes: <br/> - Calculation of the cost of a 3D printing cell based on the customer's capacity, parameters, and task. <br/>  - Delivery and installation of equipment to the workshop specifications. <br/>- Software and technical support by our programmers within 60 days after the complex is launched</div>,
        
        
        
        name: 'Your Name',
        name_placeholder: 'Text a Name',
        email: 'Your E-mail',
        email_placeholder: 'Text an e-mail',
        phone: 'Your Phone number',
        phone_placeholder: '+xxx xx-xx-xx-xxx',
        messageTitle: 'Request subject',
        messageTitle_placeholder: 'Text a subject',
        messageText: 'Your request',
        
        send_message: 'Send',
        feedback: 'Feedback',
        contacts_data: 'Contacts',
        urid_data: 'Legal address',
        adress: 'Facility adress',
        data1: <div> +381 60 60 60 311<br/>+381 11 344 13 73<br/> Strahinja Milovanovic<br/>technoaviainfo@gmail.com</div>,
        data2: <div>Tsarya Nikolaya II 1, Beograd, Serbia<br/>PIB: 220 077 77 </div>,
        data3: <div> Savski nasip 7,<br/>Belgrad</div>,
        
        footer_text: '© TECHNOAVIA Ltd. - Large Scale FGF 3D printing, equipment manufacturing from polymer materials for composite products'
       }
}